import React from 'react'
import PropTypes from 'prop-types'
import DateFilterStyled from './styledComponent'
import { detectBrowser } from '../../utils'
import MESSAGE from './message'

class DateFilterComponent extends React.PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  handleFilterClicked(data) {
    this.props.onFilterChanged(data.value)
  }

  generateDateTimeFilter() {
    const listDateTimeFilter = [
      { text: this.getMessage('day'), value: 'day', id: 'btn-date-type-day' },
      { text: this.getMessage('week'), value: 'week', id: 'btn-date-type-week' },
      { text: this.getMessage('month'), value: 'month', id: 'btn-date-type-month' },
      { text: this.getMessage('year'), value: 'year', id: 'btn-date-type-year' }
    ]
    const dateTimeFilters = listDateTimeFilter.map((obj) => {
      let filterOptionClassName = 'filter-option-container'
      const isSelected = obj.value === this.props.value
      if (isSelected) {
        filterOptionClassName += ' selected'
      }
      if (detectBrowser() === 'Firefox') {
        filterOptionClassName += ' is-firefox'
      }
      return (
        <div id={obj.id} key={obj.value} className={filterOptionClassName} onClick={() => this.handleFilterClicked(obj)}>
          {obj.text}
        </div>
      )
    })
    return dateTimeFilters
  }

  render() {
    return <DateFilterStyled>{this.generateDateTimeFilter()}</DateFilterStyled>
  }
}

DateFilterComponent.defaultProps = {
  language: 'EN',
  value: 'day'
}

DateFilterComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  language: PropTypes.string
}

export default DateFilterComponent
