const MESSAGE = {
  EN: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year'
  },
  TH: {
    day: 'วัน',
    week: 'สัปดาห์',
    month: 'เดือน',
    year: 'ปี'
  }
}
export default MESSAGE
