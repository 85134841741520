import styled from 'styled-components'

export default styled.div`
  display: flex;
  border-radius: 5px;
  .filter-option-container {
    cursor: pointer;
    min-width: 61px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Prompt', sans-serif;
    font-size: 10px;
    text-align: center;
    color: ${(props) => props.theme.darkBackground};
    border: 1px solid ${(props) => props.theme.darkBackground};

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:nth-child(n) {
      border-left: none;
    }

    &:nth-child(1) {
      border-left: 1px solid ${(props) => props.theme.darkBackground};
    }

    &:last-child {
      border-right: 1px solid ${(props) => props.theme.darkBackground};
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &.selected {
      width: 63px;
      background: ${(props) => props.theme.darkBackground};
      color: white;
    }
    &.is-firefox {
      padding: 5px 15px;
    }
  }
`
